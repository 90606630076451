import { tv, VariantProps } from '@/utils/tv'

export const cigarBarVariant = tv({
  slots: {
    base: [],
    wrapper: [
      'flex justify-center items-center gap-6 min-h-[64px]',
      'p-3 md:p-2.5',
      'bg-[#1040FF]'
    ],
    text: ['[&>b]:font-semibold [&>span>b]:text-white'],
    action: ['shrink-0']
  }
})

export type CigarBarVariantProps = VariantProps<typeof cigarBarVariant>
