import { ChangeEvent, useTransition } from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { Locale } from '@/config/locales'
import { langSwitcher } from './lang-switcher.variants'

export interface UseLangSwitcherProps {
  className?: string
  locale: Locale
}

export const useLangSwitcher = ({
  className = '',
  locale: currentLocale
}: UseLangSwitcherProps) => {
  const [isPending, startTransition] = useTransition()
  const { push } = useRouter()
  const path = usePathname()
  const searchParams = useSearchParams().toString()

  const finalSearchParams = searchParams ? `?${searchParams}` : ''

  let pathWithoutLocale = path

  if (pathWithoutLocale.startsWith(`/${currentLocale}/`)) {
    pathWithoutLocale = pathWithoutLocale.replace(`/${currentLocale}/`, '/')
  } else if (pathWithoutLocale === `/${currentLocale}`) {
    pathWithoutLocale = '/'
  }

  const styles = langSwitcher()

  const onChangeLocale = (event: ChangeEvent<HTMLSelectElement>) => {
    const newLocale = event.target.value

    startTransition(() => {
      push(`/${newLocale}${pathWithoutLocale}${finalSearchParams}`)
    })
  }

  return { styles, className, isPending, currentLocale, onChangeLocale }
}
