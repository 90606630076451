/* eslint-disable @next/next/no-img-element */
'use client'

import { Button } from '../button'
import { Text } from '../text'
import { usePathname } from 'next/navigation'
import { useCigarBar, useCigarBarProps } from './use-cigar-bar'

export type CigarBarProps = useCigarBarProps

const CigarBar = (props: CigarBarProps) => {
  const { ref, className, styles, data } = useCigarBar(props)
  const pathName = usePathname()

  // temp improvement for jam sessions. remove after it ends
  const url =
    ['/es/', '/pt/', '/fr/', '/it/', '/de/', '/ja/']
      .map((locale) => {
        if (pathName.includes(locale)) {
          return `${locale.slice(0, -1)}${data?.link?.url}`
        }
      })
      .find((link) => !!link) || data?.link.url

  return (
    // @ts-ignore
    <div ref={ref} className={styles.base({ className })}>
      {!!data && (
        <div className={styles.wrapper()}>
          {!!data?.text && (
            <Text
              className={styles.text()}
              size="cigarbar"
              color="text-white/80"
              weight="medium"
            >
              {data.text}
            </Text>
          )}
          {!!data?.link && (
            <Button
              size="sm"
              color="black"
              appearance="solid"
              href={url}
              title={data.link.text}
              className={styles.action()}
            >
              {data.link.text}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default CigarBar
